import { css } from '@emotion/react'
import { operatingZoneDimension, subscriptionUiSteps, type SubscriptionUiElement } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { ContentContainerBackoffice, Text, colors, spacing, useLanguage, useTranslate } from '@orus.eu/pharaoh'
import { LanguageProvider } from '@orus.eu/pharaoh/src/localization/language-context'
import { memo, useState } from 'react'
import { BackofficeSectionTitle } from '../../../../atoms/backoffice-section-title'
import { DropdownDimensionField } from '../../common/backoffice-quote-editor-v2/fields/dropdown-dimension-field'

export default memo(function SubscriptionFunnelTextsDocumentationPage() {
  const [operatingZone, setOperatingZone] = useState<OperatingZone>('fr')

  const translate = useTranslate()

  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Parcours de souscription</BackofficeSectionTitle>
      <DropdownDimensionField
        state={{ operatingZone }}
        valueDimension={operatingZoneDimension}
        setChanges={({ operatingZone }) => {
          if (operatingZone) setOperatingZone(operatingZone)
        }}
      />
      <LanguageProvider value={operatingZone}>
        <ContentContainerBackoffice gap={spacing[50]}>
          {subscriptionUiSteps.map((step) => {
            const title = step.title ?? (step.titleKey ? translate(step.titleKey) : undefined)
            return (
              <div key={step.id}>
                <Text variant="subtitle2">{title ?? '<custom title>'}</Text>
                {step.bodyElements.map((uiElement, index) => (
                  <UiElementTextsBlock key={index} uiElement={uiElement} />
                ))}
              </div>
            )
          })}
        </ContentContainerBackoffice>
      </LanguageProvider>
    </ContentContainerBackoffice>
  )
})

const UiElementTextsBlock = memo<{ uiElement: SubscriptionUiElement }>(function UiElementTextsBlock({ uiElement }) {
  const language = useLanguage()
  const hint = 'dimension' in uiElement && 'hint' in uiElement.dimension && uiElement.dimension.hint
  return (
    <>
      <UiElementTextsBlockName uiElement={uiElement} />
      {hint ? (
        <Text variant="body1">
          <span
            css={css`
              color: ${colors.gray[500]};
            `}
          >
            {'  ╰ Hint : '}
          </span>
          {uiElement.dimension.hints ? uiElement.dimension.hints[language] : undefined}
        </Text>
      ) : null}
    </>
  )
})

const UiElementTextsBlockName = memo<{ uiElement: SubscriptionUiElement }>(function UiElementTextsBlockName({
  uiElement,
}) {
  const translate = useTranslate()
  const language = useLanguage()
  if ('label' in uiElement) {
    return <Text variant="body1">{uiElement.label}</Text>
  }

  if (uiElement.type === 'readonly-current-country') {
    return <Text variant="body1">{translate('country')}</Text>
  }

  if (uiElement.type === 'siret') {
    return <Text variant="body1">{uiElement.dimensions.siret.displayNames[language]}</Text>
  }

  if (uiElement.type === 'empty') {
    return <></>
  }

  if ('dimension' in uiElement) {
    return <Text variant="body1">{uiElement.dimension.displayNames[language]}</Text>
  }

  if ('dimensions' in uiElement && 'value' in uiElement.dimensions) {
    return <Text variant="body1">{uiElement.dimensions.value.displayNames[language]}</Text>
  }

  if ('text' in uiElement) {
    return (
      <Text variant="body1">
        <span
          css={css`
            color: ${colors.gray[500]};
          `}
        >
          {uiElement.type} :{' '}
        </span>
        {uiElement.text}
      </Text>
    )
  }

  return (
    <Text variant="body1" color={colors.gray[500]}>
      {`custom element : ${uiElement.type}`}
    </Text>
  )
})
